@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  all: unset;
}

.CheckboxRoot {
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--black-a7);
}
.CheckboxRoot:checked[type=checkbox] {
    background-color: #556ee6;
    border-color: #556ee6;
}

.CheckboxRoot:focus {
  box-shadow: 0 0 0 2px black;
}

.CheckboxIndicator {
    background-color: #556ee6;
    border-color: #556ee6;
}

.Label {
  color: white;
  padding-left: 15px;
  font-size: 15px;
  line-height: 1;
}
